// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Footer_container__c0Q1z {
  display: flex;
  background-color: white;
  justify-content: space-around;
  align-items: center;
  padding-top: 13px;
  padding-bottom: 13px;
  padding-right: 37px;
  padding-left: 37px;
  width: 100%;
  height: 60px;
  position: sticky;
  bottom: 0;
  left: 0;
  z-index: 1000;
  border-top: 1px solid rgba(0, 0, 0, 0.25);
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);
}
`, "",{"version":3,"sources":["webpack://./src/Components/Footer/Footer.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,6BAA6B;EAC7B,mBAAmB;EACnB,iBAAiB;EACjB,oBAAoB;EACpB,mBAAmB;EACnB,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,gBAAgB;EAChB,SAAS;EACT,OAAO;EACP,aAAa;EACb,yCAAyC;EACzC,4CAA4C;AAC9C","sourcesContent":[".container {\n  display: flex;\n  background-color: white;\n  justify-content: space-around;\n  align-items: center;\n  padding-top: 13px;\n  padding-bottom: 13px;\n  padding-right: 37px;\n  padding-left: 37px;\n  width: 100%;\n  height: 60px;\n  position: sticky;\n  bottom: 0;\n  left: 0;\n  z-index: 1000;\n  border-top: 1px solid rgba(0, 0, 0, 0.25);\n  border-bottom: 1px solid rgba(0, 0, 0, 0.25);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Footer_container__c0Q1z`
};
export default ___CSS_LOADER_EXPORT___;
