// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CardColumn-HomePage_container__0srk6 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 15vw;
}
.CardColumn-HomePage_container__0srk6:not(:last-child) {
  margin-right: 7px;
}
.CardColumn-HomePage_heading__rdRI6 {
  font-size: 20px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: center;

  margin-bottom: 4px;
}
.CardColumn-HomePage_currentPillar__dchX9 {
  background-color: var(--mini-accent-color);
}
.CardColumn-HomePage_headingContainer__Tsoch {
  display: flex;
  justify-content: center;
  align-items: center;
}
.CardColumn-HomePage_arrow__sfv2e {
  fill: var(--background-color);
  width: 9px;
  height: 16px;
  &:nth-child(1) {
    margin-right: 4px;
  }
  &:nth-child(3) {
    margin-left: 4px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/Components/CardColumn/CardColumn-HomePage.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;;EAEnB,WAAW;AACb;AACA;EACE,iBAAiB;AACnB;AACA;EACE,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,mBAAmB;EACnB,kBAAkB;;EAElB,kBAAkB;AACpB;AACA;EACE,0CAA0C;AAC5C;AACA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;AACA;EACE,6BAA6B;EAC7B,UAAU;EACV,YAAY;EACZ;IACE,iBAAiB;EACnB;EACA;IACE,gBAAgB;EAClB;AACF","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n\n  width: 15vw;\n}\n.container:not(:last-child) {\n  margin-right: 7px;\n}\n.heading {\n  font-size: 20px;\n  font-weight: 400;\n  line-height: 26px;\n  letter-spacing: 0em;\n  text-align: center;\n\n  margin-bottom: 4px;\n}\n.currentPillar {\n  background-color: var(--mini-accent-color);\n}\n.headingContainer {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n.arrow {\n  fill: var(--background-color);\n  width: 9px;\n  height: 16px;\n  &:nth-child(1) {\n    margin-right: 4px;\n  }\n  &:nth-child(3) {\n    margin-left: 4px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `CardColumn-HomePage_container__0srk6`,
	"heading": `CardColumn-HomePage_heading__rdRI6`,
	"currentPillar": `CardColumn-HomePage_currentPillar__dchX9`,
	"headingContainer": `CardColumn-HomePage_headingContainer__Tsoch`,
	"arrow": `CardColumn-HomePage_arrow__sfv2e`
};
export default ___CSS_LOADER_EXPORT___;
