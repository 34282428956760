// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NavLinkIconed_link__SzZUD {
  color: var(--inactive-color);
}
.NavLinkIconed_active__MwV0A {
  color: var(--accent-color);
}
.NavLinkIconed_icon__SDUtG {
  width: 26px;
  height: 26px;
  fill: currentColor;
}
`, "",{"version":3,"sources":["webpack://./src/Components/NavLinkIconed/NavLinkIconed.module.css"],"names":[],"mappings":"AAAA;EACE,4BAA4B;AAC9B;AACA;EACE,0BAA0B;AAC5B;AACA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;AACpB","sourcesContent":[".link {\n  color: var(--inactive-color);\n}\n.active {\n  color: var(--accent-color);\n}\n.icon {\n  width: 26px;\n  height: 26px;\n  fill: currentColor;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"link": `NavLinkIconed_link__SzZUD`,
	"active": `NavLinkIconed_active__MwV0A`,
	"icon": `NavLinkIconed_icon__SDUtG`
};
export default ___CSS_LOADER_EXPORT___;
