// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CardColumn-Editing_container__DO3hq {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  pointer-events: auto;
}
.CardColumn-Editing_container__DO3hq:not(:last-child) {
  margin-right: 7px;
}
.CardColumn-Editing_heading__n4ulu {
  font-size: 20px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: center;

  margin-bottom: 4px;
}
.CardColumn-Editing_currentPillar__mvQFY {
  background-color: var(--mini-accent-color);
}
.CardColumn-Editing_editingArrowIcon__zLcJ2 {
  width: 28px;
  height: 21px;
  margin-bottom: 8px;
  fill: var(--accent-color);
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}
`, "",{"version":3,"sources":["webpack://./src/Components/CardColumn/CardColumn-Editing.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,oBAAoB;AACtB;AACA;EACE,iBAAiB;AACnB;AACA;EACE,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,mBAAmB;EACnB,kBAAkB;;EAElB,kBAAkB;AACpB;AACA;EACE,0CAA0C;AAC5C;AACA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,yBAAyB;EAEzB,oDAAoD;AACtD","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  pointer-events: auto;\n}\n.container:not(:last-child) {\n  margin-right: 7px;\n}\n.heading {\n  font-size: 20px;\n  font-weight: 400;\n  line-height: 26px;\n  letter-spacing: 0em;\n  text-align: center;\n\n  margin-bottom: 4px;\n}\n.currentPillar {\n  background-color: var(--mini-accent-color);\n}\n.editingArrowIcon {\n  width: 28px;\n  height: 21px;\n  margin-bottom: 8px;\n  fill: var(--accent-color);\n  -webkit-filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));\n  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `CardColumn-Editing_container__DO3hq`,
	"heading": `CardColumn-Editing_heading__n4ulu`,
	"currentPillar": `CardColumn-Editing_currentPillar__mvQFY`,
	"editingArrowIcon": `CardColumn-Editing_editingArrowIcon__zLcJ2`
};
export default ___CSS_LOADER_EXPORT___;
