// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AnimalLogo-CardGridItem_icon__ENU4M {
  width: 80%;
  height: 80%;
  max-width: 94px;
  max-height: 94px;
  padding-top: 10px;
  padding-bottom: 10px;
  fill: white;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}
`, "",{"version":3,"sources":["webpack://./src/Components/AnimalLogo/AnimalLogo-CardGridItem.module.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,WAAW;EACX,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,oBAAoB;EACpB,WAAW;EAEX,oDAAoD;AACtD","sourcesContent":[".icon {\n  width: 80%;\n  height: 80%;\n  max-width: 94px;\n  max-height: 94px;\n  padding-top: 10px;\n  padding-bottom: 10px;\n  fill: white;\n  -webkit-filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));\n  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"icon": `AnimalLogo-CardGridItem_icon__ENU4M`
};
export default ___CSS_LOADER_EXPORT___;
