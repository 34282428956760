// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Pic-HomePage_image__FOb3T {
  width: 100%;
  height: 100%;
}
.Pic-HomePage_imageWrapper__PlZEX,
.Pic-HomePage_noImage__qpOjc {
  width: 52px;
  height: 47px;
  margin-bottom: 4px;
  box-shadow: var(--bottom-shadow);
}
.Pic-HomePage_whiteBorder__jeTUA,
.Pic-HomePage_grayBorder__\\+md3M,
.Pic-HomePage_blackBorder__44Xth {
  border-width: 3px;
  border-radius: 5px;
  overflow: hidden;
  border-style: solid;
}
.Pic-HomePage_whiteBorder__jeTUA {
  border-color: white;
  background-color: white;
}
.Pic-HomePage_grayBorder__\\+md3M {
  border-color: gray;
  background-color: gray;
}
.Pic-HomePage_blackBorder__44Xth {
  width: 100%;
  height: 100%;
  border-color: black;
  background-color: black;
}
.Pic-HomePage_name__KMZtI {
  font-family: Roboto;
  font-size: 13px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;

  margin-bottom: 4px;
}
.Pic-HomePage_noImage__qpOjc {
  background-color: rgba(0, 0, 0, 0.3);
}
`, "",{"version":3,"sources":["webpack://./src/Components/AnimalElementPic/Pic-HomePage.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;AACd;AACA;;EAEE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,gCAAgC;AAClC;AACA;;;EAGE,iBAAiB;EACjB,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;AACrB;AACA;EACE,mBAAmB;EACnB,uBAAuB;AACzB;AACA;EACE,kBAAkB;EAClB,sBAAsB;AACxB;AACA;EACE,WAAW;EACX,YAAY;EACZ,mBAAmB;EACnB,uBAAuB;AACzB;AACA;EACE,mBAAmB;EACnB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,mBAAmB;EACnB,gBAAgB;;EAEhB,kBAAkB;AACpB;AACA;EACE,oCAAoC;AACtC","sourcesContent":[".image {\n  width: 100%;\n  height: 100%;\n}\n.imageWrapper,\n.noImage {\n  width: 52px;\n  height: 47px;\n  margin-bottom: 4px;\n  box-shadow: var(--bottom-shadow);\n}\n.whiteBorder,\n.grayBorder,\n.blackBorder {\n  border-width: 3px;\n  border-radius: 5px;\n  overflow: hidden;\n  border-style: solid;\n}\n.whiteBorder {\n  border-color: white;\n  background-color: white;\n}\n.grayBorder {\n  border-color: gray;\n  background-color: gray;\n}\n.blackBorder {\n  width: 100%;\n  height: 100%;\n  border-color: black;\n  background-color: black;\n}\n.name {\n  font-family: Roboto;\n  font-size: 13px;\n  font-weight: 400;\n  line-height: 15px;\n  letter-spacing: 0em;\n  text-align: left;\n\n  margin-bottom: 4px;\n}\n.noImage {\n  background-color: rgba(0, 0, 0, 0.3);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"image": `Pic-HomePage_image__FOb3T`,
	"imageWrapper": `Pic-HomePage_imageWrapper__PlZEX`,
	"noImage": `Pic-HomePage_noImage__qpOjc`,
	"whiteBorder": `Pic-HomePage_whiteBorder__jeTUA`,
	"grayBorder": `Pic-HomePage_grayBorder__+md3M`,
	"blackBorder": `Pic-HomePage_blackBorder__44Xth`,
	"name": `Pic-HomePage_name__KMZtI`
};
export default ___CSS_LOADER_EXPORT___;
