// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Settings_menuItemContainer__yWxRB {
  padding: 13px;
  background-color: var(--light-accent-color);
  &:nth-child(1) {
    padding-top: 26px;
  }
  &:not(:last-child) {
    border-bottom: 1px solid var(--background-color);
  }
}
.Settings_profileInfo__d8wSk {
  display: block;
  padding: 4px;
  border-radius: 5px;
  text-align: left;
  line-height: normal;
  color: var(--main-text-color);
}
`, "",{"version":3,"sources":["webpack://./src/Pages/Settings/Settings.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,2CAA2C;EAC3C;IACE,iBAAiB;EACnB;EACA;IACE,gDAAgD;EAClD;AACF;AACA;EACE,cAAc;EACd,YAAY;EACZ,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;EACnB,6BAA6B;AAC/B","sourcesContent":[".menuItemContainer {\n  padding: 13px;\n  background-color: var(--light-accent-color);\n  &:nth-child(1) {\n    padding-top: 26px;\n  }\n  &:not(:last-child) {\n    border-bottom: 1px solid var(--background-color);\n  }\n}\n.profileInfo {\n  display: block;\n  padding: 4px;\n  border-radius: 5px;\n  text-align: left;\n  line-height: normal;\n  color: var(--main-text-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"menuItemContainer": `Settings_menuItemContainer__yWxRB`,
	"profileInfo": `Settings_profileInfo__d8wSk`
};
export default ___CSS_LOADER_EXPORT___;
