// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ModalPillars_container__B2rwD {
  background-color: var(--background-color);
  padding-top: 13px;
  padding-bottom: 13px;
  padding-right: 13px;
  padding-left: 13px;
}
.ModalPillars_pillarsContainer__035Ob {
  background-color: var(--background-color);
  /* padding-left: 11px;
  padding-right: 11px; */
  transform: translateX(-13px);
  width: calc(100% + 13px * 2);
}
.ModalPillars_pillarsInnerContainer__No-hi {
  display: flex;
  overflow: scroll;
  padding-left: 13px;
  padding-right: 13px;
}
`, "",{"version":3,"sources":["webpack://./src/Components/ModalComponents/ModalPillars/ModalPillars.module.css"],"names":[],"mappings":"AAAA;EACE,yCAAyC;EACzC,iBAAiB;EACjB,oBAAoB;EACpB,mBAAmB;EACnB,kBAAkB;AACpB;AACA;EACE,yCAAyC;EACzC;wBACsB;EACtB,4BAA4B;EAC5B,4BAA4B;AAC9B;AACA;EACE,aAAa;EACb,gBAAgB;EAChB,kBAAkB;EAClB,mBAAmB;AACrB","sourcesContent":[".container {\n  background-color: var(--background-color);\n  padding-top: 13px;\n  padding-bottom: 13px;\n  padding-right: 13px;\n  padding-left: 13px;\n}\n.pillarsContainer {\n  background-color: var(--background-color);\n  /* padding-left: 11px;\n  padding-right: 11px; */\n  transform: translateX(-13px);\n  width: calc(100% + 13px * 2);\n}\n.pillarsInnerContainer {\n  display: flex;\n  overflow: scroll;\n  padding-left: 13px;\n  padding-right: 13px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `ModalPillars_container__B2rwD`,
	"pillarsContainer": `ModalPillars_pillarsContainer__035Ob`,
	"pillarsInnerContainer": `ModalPillars_pillarsInnerContainer__No-hi`
};
export default ___CSS_LOADER_EXPORT___;
