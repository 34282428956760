// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CardColumn-CardGridItem_container__FU7X1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 2px;
  max-width: 25px;
}
.CardColumn-CardGridItem_heading__YGCtF {
  display: none;
}
`, "",{"version":3,"sources":["webpack://./src/Components/CardColumn/CardColumn-CardGridItem.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,iBAAiB;EACjB,eAAe;AACjB;AACA;EACE,aAAa;AACf","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  margin-right: 2px;\n  max-width: 25px;\n}\n.heading {\n  display: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `CardColumn-CardGridItem_container__FU7X1`,
	"heading": `CardColumn-CardGridItem_heading__YGCtF`
};
export default ___CSS_LOADER_EXPORT___;
