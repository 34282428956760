// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CardListItem_section__0aw3b {
  padding-left: 13px;
  padding-right: 13px;
  padding-top: 5px;
  padding-bottom: 5px;
  &:first-child {
    padding-top: 13px;
  }
  &:last-child {
    padding-bottom: 13px;
  }
}
.CardListItem_container__yOtYW {
  padding: 7px;
  height: 48px;
  position: relative;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.CardListItem_name__cx7WU,
.CardListItem_birthdate__jSk6X {
  display: block;
  height: 15px;
  line-height: 15px;
  font-size: 15px;
  text-align: left;
  width: calc(100% - 20px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.CardListItem_cardInfoContainer__O-S5e {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  width: calc(100% - 50px);
  height: 100%;
}
.CardListItem_animalLogoContainer__KbmJd {
  min-width: 50px;
  height: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/Components/CardListItem/CardListItem.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,mBAAmB;EACnB,gBAAgB;EAChB,mBAAmB;EACnB;IACE,iBAAiB;EACnB;EACA;IACE,oBAAoB;EACtB;AACF;AACA;EACE,YAAY;EACZ,YAAY;EACZ,kBAAkB;EAClB,kBAAkB;EAClB,aAAa;EACb,mBAAmB;EACnB,2BAA2B;AAC7B;AACA;;EAEE,cAAc;EACd,YAAY;EACZ,iBAAiB;EACjB,eAAe;EACf,gBAAgB;EAChB,wBAAwB;EACxB,mBAAmB;EACnB,gBAAgB;EAChB,uBAAuB;AACzB;AACA;EACE,aAAa;EACb,uBAAuB;EACvB,8BAA8B;EAC9B,sBAAsB;EACtB,wBAAwB;EACxB,YAAY;AACd;AACA;EACE,eAAe;EACf,YAAY;AACd","sourcesContent":[".section {\n  padding-left: 13px;\n  padding-right: 13px;\n  padding-top: 5px;\n  padding-bottom: 5px;\n  &:first-child {\n    padding-top: 13px;\n  }\n  &:last-child {\n    padding-bottom: 13px;\n  }\n}\n.container {\n  padding: 7px;\n  height: 48px;\n  position: relative;\n  border-radius: 5px;\n  display: flex;\n  align-items: center;\n  justify-content: flex-start;\n}\n.name,\n.birthdate {\n  display: block;\n  height: 15px;\n  line-height: 15px;\n  font-size: 15px;\n  text-align: left;\n  width: calc(100% - 20px);\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n}\n.cardInfoContainer {\n  display: flex;\n  align-items: flex-start;\n  justify-content: space-between;\n  flex-direction: column;\n  width: calc(100% - 50px);\n  height: 100%;\n}\n.animalLogoContainer {\n  min-width: 50px;\n  height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"section": `CardListItem_section__0aw3b`,
	"container": `CardListItem_container__yOtYW`,
	"name": `CardListItem_name__cx7WU`,
	"birthdate": `CardListItem_birthdate__jSk6X`,
	"cardInfoContainer": `CardListItem_cardInfoContainer__O-S5e`,
	"animalLogoContainer": `CardListItem_animalLogoContainer__KbmJd`
};
export default ___CSS_LOADER_EXPORT___;
