// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.IconedCardInfoList-HomePage_container__ubX2G {
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 23px;
  padding-right: calc(23px + 2rem);
}
.IconedCardInfoList-HomePage_list__oZ4CX {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.IconedCardInfoList-HomePage_city__qhEJk {
  display: block;
  height: min-content;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  color: black;
  margin-top: 6px;
  margin-left: 2rem;
}
`, "",{"version":3,"sources":["webpack://./src/Components/IconedCardInfoList/IconedCardInfoList-HomePage.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,oBAAoB;EACpB,kBAAkB;EAClB,gCAAgC;AAClC;AACA;EACE,aAAa;EACb,6BAA6B;EAC7B,mBAAmB;AACrB;AACA;EACE,cAAc;EACd,mBAAmB;EACnB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,mBAAmB;EACnB,kBAAkB;EAClB,YAAY;EACZ,eAAe;EACf,iBAAiB;AACnB","sourcesContent":[".container {\n  padding-top: 15px;\n  padding-bottom: 15px;\n  padding-left: 23px;\n  padding-right: calc(23px + 2rem);\n}\n.list {\n  display: flex;\n  justify-content: space-evenly;\n  align-items: center;\n}\n.city {\n  display: block;\n  height: min-content;\n  font-size: 15px;\n  font-weight: 400;\n  line-height: 20px;\n  letter-spacing: 0em;\n  text-align: center;\n  color: black;\n  margin-top: 6px;\n  margin-left: 2rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `IconedCardInfoList-HomePage_container__ubX2G`,
	"list": `IconedCardInfoList-HomePage_list__oZ4CX`,
	"city": `IconedCardInfoList-HomePage_city__qhEJk`
};
export default ___CSS_LOADER_EXPORT___;
