// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `._404Page_container__21P8Y {
  display: block;
  background-color: var(--404-background-color);
  padding-top: 60px;
  padding-left: 59px;
  padding-right: 59px;
  padding-bottom: 60px;
  min-height: 100vh;
}
._404Page_heading__bdYZj {
  color: var(--accent-color);
  font-weight: 500;
  font-size: 24px;
  text-align: center;
  margin-bottom: 27px;
}
._404Page_error__wUfBx {
  margin-top: 15px;
  font-size: 32px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0.1em;
  text-align: center;
  margin-bottom: 12px;
}
._404Page_description__bAN5T {
  font-size: 20px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0.1em;
  text-align: center;
  margin-bottom: 60px;
}
._404Page_button__bCvdJ {
  margin-left: auto !important;
  margin-right: auto !important;
}
`, "",{"version":3,"sources":["webpack://./src/Pages/404Page/404Page.module.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,6CAA6C;EAC7C,iBAAiB;EACjB,kBAAkB;EAClB,mBAAmB;EACnB,oBAAoB;EACpB,iBAAiB;AACnB;AACA;EACE,0BAA0B;EAC1B,gBAAgB;EAChB,eAAe;EACf,kBAAkB;EAClB,mBAAmB;AACrB;AACA;EACE,gBAAgB;EAChB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,qBAAqB;EACrB,kBAAkB;EAClB,mBAAmB;AACrB;AACA;EACE,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,qBAAqB;EACrB,kBAAkB;EAClB,mBAAmB;AACrB;AACA;EACE,4BAA4B;EAC5B,6BAA6B;AAC/B","sourcesContent":[".container {\n  display: block;\n  background-color: var(--404-background-color);\n  padding-top: 60px;\n  padding-left: 59px;\n  padding-right: 59px;\n  padding-bottom: 60px;\n  min-height: 100vh;\n}\n.heading {\n  color: var(--accent-color);\n  font-weight: 500;\n  font-size: 24px;\n  text-align: center;\n  margin-bottom: 27px;\n}\n.error {\n  margin-top: 15px;\n  font-size: 32px;\n  font-weight: 400;\n  line-height: 25px;\n  letter-spacing: 0.1em;\n  text-align: center;\n  margin-bottom: 12px;\n}\n.description {\n  font-size: 20px;\n  font-weight: 400;\n  line-height: 25px;\n  letter-spacing: 0.1em;\n  text-align: center;\n  margin-bottom: 60px;\n}\n.button {\n  margin-left: auto !important;\n  margin-right: auto !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `_404Page_container__21P8Y`,
	"heading": `_404Page_heading__bdYZj`,
	"error": `_404Page_error__wUfBx`,
	"description": `_404Page_description__bAN5T`,
	"button": `_404Page_button__bCvdJ`
};
export default ___CSS_LOADER_EXPORT___;
