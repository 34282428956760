// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AnimalLogo-CardLineItem_icon__40n9A {
  height: 100%;
  min-height: 100%;
  width: auto;
  min-width: auto;
  fill: white;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  margin-right: 9px;
}
`, "",{"version":3,"sources":["webpack://./src/Components/AnimalLogo/AnimalLogo-CardLineItem.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,gBAAgB;EAChB,WAAW;EACX,eAAe;EACf,WAAW;EAEX,oDAAoD;EACpD,iBAAiB;AACnB","sourcesContent":[".icon {\n  height: 100%;\n  min-height: 100%;\n  width: auto;\n  min-width: auto;\n  fill: white;\n  -webkit-filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));\n  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));\n  margin-right: 9px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"icon": `AnimalLogo-CardLineItem_icon__40n9A`
};
export default ___CSS_LOADER_EXPORT___;
