// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Pic-Calculator_image__1zkdU {
  width: 100%;
  height: 100%;
}
.Pic-Calculator_imageWrapper__ddl5- {
  width: 57px;
  height: auto;
  margin-bottom: 4px;
  box-shadow: var(--bottom-shadow);
  aspect-ratio: 52 / 47;
}
.Pic-Calculator_whiteBorder__yQ3Ni,
.Pic-Calculator_grayBorder__Ri2Rn,
.Pic-Calculator_blackBorder__0CaOX {
  border-radius: 5px;
  overflow: hidden;
  border-width: 3px;
  border-style: solid;
}
.Pic-Calculator_whiteBorder__yQ3Ni {
  border-color: white;
  background-color: white;
}
.Pic-Calculator_grayBorder__Ri2Rn {
  border-color: gray;
  background-color: gray;
}
.Pic-Calculator_blackBorder__0CaOX {
  width: 100%;
  height: 100%;
  border-color: black;
  background-color: black;
}
.Pic-Calculator_name__aKFyU {
  font-family: Roboto;
  font-size: 13px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  height: 1rem;

  margin-bottom: 4px;
}
.Pic-Calculator_noImageBorder__bdTsV {
  border-width: 1px;
  border-radius: 5px;
  overflow: hidden;
  border-style: solid;
  border-color: gray;
}
.Pic-Calculator_noImage__sXRRv {
  background-color: initial;
  background-size: 100%;
  aspect-ratio: 52 / 47;
  height: 100%;
  border-radius: 5px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}
`, "",{"version":3,"sources":["webpack://./src/Components/AnimalElementPic/Pic-Calculator.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;AACd;AACA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,gCAAgC;EAChC,qBAAqB;AACvB;AACA;;;EAGE,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;EACjB,mBAAmB;AACrB;AACA;EACE,mBAAmB;EACnB,uBAAuB;AACzB;AACA;EACE,kBAAkB;EAClB,sBAAsB;AACxB;AACA;EACE,WAAW;EACX,YAAY;EACZ,mBAAmB;EACnB,uBAAuB;AACzB;AACA;EACE,mBAAmB;EACnB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,mBAAmB;EACnB,gBAAgB;EAChB,YAAY;;EAEZ,kBAAkB;AACpB;AACA;EACE,iBAAiB;EACjB,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;EACnB,kBAAkB;AACpB;AACA;EACE,yBAAyB;EACzB,qBAAqB;EACrB,qBAAqB;EACrB,YAAY;EACZ,kBAAkB;EAElB,oDAAoD;AACtD","sourcesContent":[".image {\n  width: 100%;\n  height: 100%;\n}\n.imageWrapper {\n  width: 57px;\n  height: auto;\n  margin-bottom: 4px;\n  box-shadow: var(--bottom-shadow);\n  aspect-ratio: 52 / 47;\n}\n.whiteBorder,\n.grayBorder,\n.blackBorder {\n  border-radius: 5px;\n  overflow: hidden;\n  border-width: 3px;\n  border-style: solid;\n}\n.whiteBorder {\n  border-color: white;\n  background-color: white;\n}\n.grayBorder {\n  border-color: gray;\n  background-color: gray;\n}\n.blackBorder {\n  width: 100%;\n  height: 100%;\n  border-color: black;\n  background-color: black;\n}\n.name {\n  font-family: Roboto;\n  font-size: 13px;\n  font-weight: 400;\n  line-height: 15px;\n  letter-spacing: 0em;\n  text-align: left;\n  height: 1rem;\n\n  margin-bottom: 4px;\n}\n.noImageBorder {\n  border-width: 1px;\n  border-radius: 5px;\n  overflow: hidden;\n  border-style: solid;\n  border-color: gray;\n}\n.noImage {\n  background-color: initial;\n  background-size: 100%;\n  aspect-ratio: 52 / 47;\n  height: 100%;\n  border-radius: 5px;\n  -webkit-filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));\n  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"image": `Pic-Calculator_image__1zkdU`,
	"imageWrapper": `Pic-Calculator_imageWrapper__ddl5-`,
	"whiteBorder": `Pic-Calculator_whiteBorder__yQ3Ni`,
	"grayBorder": `Pic-Calculator_grayBorder__Ri2Rn`,
	"blackBorder": `Pic-Calculator_blackBorder__0CaOX`,
	"name": `Pic-Calculator_name__aKFyU`,
	"noImageBorder": `Pic-Calculator_noImageBorder__bdTsV`,
	"noImage": `Pic-Calculator_noImage__sXRRv`
};
export default ___CSS_LOADER_EXPORT___;
