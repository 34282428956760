// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.InputTodayModal_container__3kgbf {
  position: absolute;
  top: 0px;
  background-color: var(--background-color);
  padding-top: 26px;
  padding-left: 26px;
  padding-right: 26px;
  padding-bottom: 26px;
  width: 100%;
}
.InputTodayModal_inputList__DqBHt {
  display: flex;
  justify-content: space-between;
  padding-left: calc((100% - 26px - 52px * 4) / 7);
  padding-right: calc((100% - 26px - 52px * 4) / 7);
  margin-bottom: 26px;
}
.InputTodayModal_buttonList__gwdGL {
  display: flex;
  align-items: center;
  justify-content: center;
}
.InputTodayModal_buttonList__gwdGL > *:first-child {
  margin-right: 15px;
}
.InputTodayModal_buttonList__gwdGL > *:last-child {
  margin-right: 0;
}
.InputTodayModal_buttonList__gwdGL > * {
  padding: 7px !important;
  width: 131px;
  height: 51px;
}
`, "",{"version":3,"sources":["webpack://./src/Components/InputTodayModal/InputTodayModal.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,QAAQ;EACR,yCAAyC;EACzC,iBAAiB;EACjB,kBAAkB;EAClB,mBAAmB;EACnB,oBAAoB;EACpB,WAAW;AACb;AACA;EACE,aAAa;EACb,8BAA8B;EAC9B,gDAAgD;EAChD,iDAAiD;EACjD,mBAAmB;AACrB;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;AACA;EACE,kBAAkB;AACpB;AACA;EACE,eAAe;AACjB;AACA;EACE,uBAAuB;EACvB,YAAY;EACZ,YAAY;AACd","sourcesContent":[".container {\n  position: absolute;\n  top: 0px;\n  background-color: var(--background-color);\n  padding-top: 26px;\n  padding-left: 26px;\n  padding-right: 26px;\n  padding-bottom: 26px;\n  width: 100%;\n}\n.inputList {\n  display: flex;\n  justify-content: space-between;\n  padding-left: calc((100% - 26px - 52px * 4) / 7);\n  padding-right: calc((100% - 26px - 52px * 4) / 7);\n  margin-bottom: 26px;\n}\n.buttonList {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n.buttonList > *:first-child {\n  margin-right: 15px;\n}\n.buttonList > *:last-child {\n  margin-right: 0;\n}\n.buttonList > * {\n  padding: 7px !important;\n  width: 131px;\n  height: 51px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `InputTodayModal_container__3kgbf`,
	"inputList": `InputTodayModal_inputList__DqBHt`,
	"buttonList": `InputTodayModal_buttonList__gwdGL`
};
export default ___CSS_LOADER_EXPORT___;
