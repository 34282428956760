// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MyModal_сontainer__sYAHH {
  position: absolute;
  top: 55px;
  left: 0;
  width: 100%;
}
.MyModal_closeIcon__1pfbL {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10000;
  transform: translate(-50%, 50%);
  fill: var(--accent-color);
}
`, "",{"version":3,"sources":["webpack://./src/Components/MyModal/MyModal.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,SAAS;EACT,OAAO;EACP,WAAW;AACb;AACA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,MAAM;EACN,QAAQ;EACR,cAAc;EACd,+BAA+B;EAC/B,yBAAyB;AAC3B","sourcesContent":[".сontainer {\n  position: absolute;\n  top: 55px;\n  left: 0;\n  width: 100%;\n}\n.closeIcon {\n  width: 20px;\n  height: 20px;\n  position: absolute;\n  top: 0;\n  right: 0;\n  z-index: 10000;\n  transform: translate(-50%, 50%);\n  fill: var(--accent-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"сontainer": `MyModal_сontainer__sYAHH`,
	"closeIcon": `MyModal_closeIcon__1pfbL`
};
export default ___CSS_LOADER_EXPORT___;
