// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SearchField_container__U\\+GXg {
  width: min-content;
  overflow: hidden;
}
.SearchField_searchContainer__nl8DU {
  border-radius: 5px;
  background-color: var(--background-color);
  border: 1px solid var(--border-color);
  display: flex;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 9px;
  padding-right: 25px;
}
.SearchField_icon__P3uAW {
  margin-right: 12px;
  width: 14px;
  min-width: 14px;
  max-width: 14px;
  height: auto;
}
.SearchField_placeholder__9RHHg {
  color: #8894bf;
  text-wrap: nowrap;
  font-family: Roboto Slab;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: left;
  height: min-content;
  border: none;
  width: 100%;
  &:focus-visible {
    border: none;
    outline-width: 0;
  }
  &::placeholder {
    opacity: 1;
  }
}
.SearchField_searchResultList__ru5FD {
  margin-top: 10px;
  width: 70%;
  margin-right: auto;
  margin-left: auto;
  text-align: left;
  overflow-y: scroll;
  max-height: calc(90dvh - 55px - 52px - 50px);
}
.SearchField_searchResultItem__OvJD2 {
  color: white;
  cursor: pointer;
  &:not(:last-child) {
    margin-bottom: 10px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/Components/SearchField/SearchField.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,gBAAgB;AAClB;AACA;EACE,kBAAkB;EAClB,yCAAyC;EACzC,qCAAqC;EACrC,aAAa;EACb,gBAAgB;EAChB,mBAAmB;EACnB,iBAAiB;EACjB,mBAAmB;AACrB;AACA;EACE,kBAAkB;EAClB,WAAW;EACX,eAAe;EACf,eAAe;EACf,YAAY;AACd;AACA;EACE,cAAc;EACd,iBAAiB;EACjB,wBAAwB;EACxB,eAAe;EACf,gBAAgB;EAChB,mBAAmB;EACnB,gBAAgB;EAChB,mBAAmB;EACnB,YAAY;EACZ,WAAW;EACX;IACE,YAAY;IACZ,gBAAgB;EAClB;EACA;IACE,UAAU;EACZ;AACF;AACA;EACE,gBAAgB;EAChB,UAAU;EACV,kBAAkB;EAClB,iBAAiB;EACjB,gBAAgB;EAChB,kBAAkB;EAClB,4CAA4C;AAC9C;AACA;EACE,YAAY;EACZ,eAAe;EACf;IACE,mBAAmB;EACrB;AACF","sourcesContent":[".container {\n  width: min-content;\n  overflow: hidden;\n}\n.searchContainer {\n  border-radius: 5px;\n  background-color: var(--background-color);\n  border: 1px solid var(--border-color);\n  display: flex;\n  padding-top: 5px;\n  padding-bottom: 5px;\n  padding-left: 9px;\n  padding-right: 25px;\n}\n.icon {\n  margin-right: 12px;\n  width: 14px;\n  min-width: 14px;\n  max-width: 14px;\n  height: auto;\n}\n.placeholder {\n  color: #8894bf;\n  text-wrap: nowrap;\n  font-family: Roboto Slab;\n  font-size: 15px;\n  font-weight: 400;\n  letter-spacing: 0em;\n  text-align: left;\n  height: min-content;\n  border: none;\n  width: 100%;\n  &:focus-visible {\n    border: none;\n    outline-width: 0;\n  }\n  &::placeholder {\n    opacity: 1;\n  }\n}\n.searchResultList {\n  margin-top: 10px;\n  width: 70%;\n  margin-right: auto;\n  margin-left: auto;\n  text-align: left;\n  overflow-y: scroll;\n  max-height: calc(90dvh - 55px - 52px - 50px);\n}\n.searchResultItem {\n  color: white;\n  cursor: pointer;\n  &:not(:last-child) {\n    margin-bottom: 10px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `SearchField_container__U+GXg`,
	"searchContainer": `SearchField_searchContainer__nl8DU`,
	"icon": `SearchField_icon__P3uAW`,
	"placeholder": `SearchField_placeholder__9RHHg`,
	"searchResultList": `SearchField_searchResultList__ru5FD`,
	"searchResultItem": `SearchField_searchResultItem__OvJD2`
};
export default ___CSS_LOADER_EXPORT___;
