// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CardOptionsButton_wrapper__MPWGX {
  position: absolute;
  padding: 15px;
  top: -8px;
  right: -8px;
  box-sizing: content-box;
  width: 5px;
  height: 26px;
  cursor: pointer;
}
.CardOptionsButton_threePointsIcon__\\+\\+FJI {
  position: absolute;
  top: 15px;
  right: 15px;
  fill: white;
  width: 5px;
  height: 26px;
}
`, "",{"version":3,"sources":["webpack://./src/Components/CardOptionsButton/CardOptionsButton.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,aAAa;EACb,SAAS;EACT,WAAW;EACX,uBAAuB;EACvB,UAAU;EACV,YAAY;EACZ,eAAe;AACjB;AACA;EACE,kBAAkB;EAClB,SAAS;EACT,WAAW;EACX,WAAW;EACX,UAAU;EACV,YAAY;AACd","sourcesContent":[".wrapper {\n  position: absolute;\n  padding: 15px;\n  top: -8px;\n  right: -8px;\n  box-sizing: content-box;\n  width: 5px;\n  height: 26px;\n  cursor: pointer;\n}\n.threePointsIcon {\n  position: absolute;\n  top: 15px;\n  right: 15px;\n  fill: white;\n  width: 5px;\n  height: 26px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `CardOptionsButton_wrapper__MPWGX`,
	"threePointsIcon": `CardOptionsButton_threePointsIcon__++FJI`
};
export default ___CSS_LOADER_EXPORT___;
