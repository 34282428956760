// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CardOptionsModal_container__eNURu {
  background-color: transparent;
  position: relative;
  width: 100%;
  height: 100vh;
}
.CardOptionsModal_groupsList__cwY-d,
.CardOptionsModal_list__DwhNq {
  height: 239px;
  width: calc(50% - 13px * 2);
  position: absolute;
}
.CardOptionsModal_list__DwhNq {
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}
.CardOptionsModal_groupsList__cwY-d {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}
/* .groupsList > :not(:last-child) {
  margin-bottom: 22px;
} */
.CardOptionsModal_icon__2t2lY {
  width: 5px;
  height: 26px;
  fill: white;
  top: 7px;
  right: 7px;
  position: absolute;
}
.CardOptionsModal_connectingLine__mgtWi {
  position: absolute;
  height: 1px;
  border: 1px solid var(--background-color);
}
`, "",{"version":3,"sources":["webpack://./src/Components/CardOptionsModal/CardOptionsModal.module.css"],"names":[],"mappings":"AAAA;EACE,6BAA6B;EAC7B,kBAAkB;EAClB,WAAW;EACX,aAAa;AACf;AACA;;EAEE,aAAa;EACb,2BAA2B;EAC3B,kBAAkB;AACpB;AACA;EACE,iBAAiB;EACjB,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,6BAA6B;AAC/B;AACA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,6BAA6B;AAC/B;AACA;;GAEG;AACH;EACE,UAAU;EACV,YAAY;EACZ,WAAW;EACX,QAAQ;EACR,UAAU;EACV,kBAAkB;AACpB;AACA;EACE,kBAAkB;EAClB,WAAW;EACX,yCAAyC;AAC3C","sourcesContent":[".container {\n  background-color: transparent;\n  position: relative;\n  width: 100%;\n  height: 100vh;\n}\n.groupsList,\n.list {\n  height: 239px;\n  width: calc(50% - 13px * 2);\n  position: absolute;\n}\n.list {\n  padding-top: 20px;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: space-evenly;\n}\n.groupsList {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: space-evenly;\n}\n/* .groupsList > :not(:last-child) {\n  margin-bottom: 22px;\n} */\n.icon {\n  width: 5px;\n  height: 26px;\n  fill: white;\n  top: 7px;\n  right: 7px;\n  position: absolute;\n}\n.connectingLine {\n  position: absolute;\n  height: 1px;\n  border: 1px solid var(--background-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `CardOptionsModal_container__eNURu`,
	"groupsList": `CardOptionsModal_groupsList__cwY-d`,
	"list": `CardOptionsModal_list__DwhNq`,
	"icon": `CardOptionsModal_icon__2t2lY`,
	"connectingLine": `CardOptionsModal_connectingLine__mgtWi`
};
export default ___CSS_LOADER_EXPORT___;
