// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FallingStar_container__Pse9n {
  background-color: aqua;
  position: relative;
  font-family: "Lilita One", cursive;
  border-radius: 5px;
}
.FallingStar_white__xxwtO {
  background-color: white;
  border: 1px solid gray;
  & .FallingStar_year__Lv0lU {
    -webkit-text-stroke: 1px #1e1e1e; /* width and color */
  }
}
.FallingStar_yellow__\\+MkVp {
  background-color: var(--yellow-color);
}
.FallingStar_lightGreen__-tBo8 {
  background-color: var(--light-green-color);
}
.FallingStar_darkGreen__Umkk4 {
  background-color: var(--dark-green-color);
  color: white;
}
.FallingStar_red__wLBUn {
  background-color: var(--red-color);
  color: white;
}
.FallingStar_black__6IR5O {
  background-color: black;
  color: white;
  & .FallingStar_month__IQNQT {
    color: white;
  }
}
.FallingStar_purple__z6n-L {
  background-color: var(--purple-color);
  color: white;
}
.FallingStar_year__Lv0lU {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
}
.FallingStar_month__IQNQT {
  position: absolute;
  top: 25%;
  left: 20%;
  transform: translate(-50%, -50%);
  font-size: 12px;
  color: black;
}
`, "",{"version":3,"sources":["webpack://./src/Components/FallingStar/FallingStar.module.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,kBAAkB;EAClB,kCAAkC;EAClC,kBAAkB;AACpB;AACA;EACE,uBAAuB;EACvB,sBAAsB;EACtB;IACE,gCAAgC,EAAE,oBAAoB;EACxD;AACF;AACA;EACE,qCAAqC;AACvC;AACA;EACE,0CAA0C;AAC5C;AACA;EACE,yCAAyC;EACzC,YAAY;AACd;AACA;EACE,kCAAkC;EAClC,YAAY;AACd;AACA;EACE,uBAAuB;EACvB,YAAY;EACZ;IACE,YAAY;EACd;AACF;AACA;EACE,qCAAqC;EACrC,YAAY;AACd;AACA;EACE,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,gCAAgC;EAChC,YAAY;AACd;AACA;EACE,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,gCAAgC;EAChC,eAAe;EACf,YAAY;AACd","sourcesContent":[".container {\n  background-color: aqua;\n  position: relative;\n  font-family: \"Lilita One\", cursive;\n  border-radius: 5px;\n}\n.white {\n  background-color: white;\n  border: 1px solid gray;\n  & .year {\n    -webkit-text-stroke: 1px #1e1e1e; /* width and color */\n  }\n}\n.yellow {\n  background-color: var(--yellow-color);\n}\n.lightGreen {\n  background-color: var(--light-green-color);\n}\n.darkGreen {\n  background-color: var(--dark-green-color);\n  color: white;\n}\n.red {\n  background-color: var(--red-color);\n  color: white;\n}\n.black {\n  background-color: black;\n  color: white;\n  & .month {\n    color: white;\n  }\n}\n.purple {\n  background-color: var(--purple-color);\n  color: white;\n}\n.year {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  color: white;\n}\n.month {\n  position: absolute;\n  top: 25%;\n  left: 20%;\n  transform: translate(-50%, -50%);\n  font-size: 12px;\n  color: black;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `FallingStar_container__Pse9n`,
	"white": `FallingStar_white__xxwtO`,
	"year": `FallingStar_year__Lv0lU`,
	"yellow": `FallingStar_yellow__+MkVp`,
	"lightGreen": `FallingStar_lightGreen__-tBo8`,
	"darkGreen": `FallingStar_darkGreen__Umkk4`,
	"red": `FallingStar_red__wLBUn`,
	"black": `FallingStar_black__6IR5O`,
	"month": `FallingStar_month__IQNQT`,
	"purple": `FallingStar_purple__z6n-L`
};
export default ___CSS_LOADER_EXPORT___;
