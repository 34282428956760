// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CardDeletionPopup_modal__zoyfa {
  height: 100dvh;
  width: 100%;
  padding-top: 52px;
}
.CardDeletionPopup_container__wep9E {
  background-color: white;
  width: 100%;
  padding-top: 25px;
  padding-bottom: 25px;
  padding-left: 40px;
  padding-right: 40px;
}
.CardDeletionPopup_heading__LBoq6 {
  font-size: 20px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;

  margin-bottom: 15px;

  color: var(--main-text-color);
}

.CardDeletionPopup_buttonsContainer__b6jhi {
  display: flex;
  align-items: center;
  justify-content: space-around;
  overflow: hidden;
  & > *:not(:last-child) {
    margin-right: 13px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/Components/CardDeletionPopup/CardDeletionPopup.module.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,WAAW;EACX,iBAAiB;AACnB;AACA;EACE,uBAAuB;EACvB,WAAW;EACX,iBAAiB;EACjB,oBAAoB;EACpB,kBAAkB;EAClB,mBAAmB;AACrB;AACA;EACE,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,mBAAmB;EACnB,kBAAkB;;EAElB,mBAAmB;;EAEnB,6BAA6B;AAC/B;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,6BAA6B;EAC7B,gBAAgB;EAChB;IACE,kBAAkB;EACpB;AACF","sourcesContent":[".modal {\n  height: 100dvh;\n  width: 100%;\n  padding-top: 52px;\n}\n.container {\n  background-color: white;\n  width: 100%;\n  padding-top: 25px;\n  padding-bottom: 25px;\n  padding-left: 40px;\n  padding-right: 40px;\n}\n.heading {\n  font-size: 20px;\n  font-weight: 400;\n  line-height: 22px;\n  letter-spacing: 0em;\n  text-align: center;\n\n  margin-bottom: 15px;\n\n  color: var(--main-text-color);\n}\n\n.buttonsContainer {\n  display: flex;\n  align-items: center;\n  justify-content: space-around;\n  overflow: hidden;\n  & > *:not(:last-child) {\n    margin-right: 13px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": `CardDeletionPopup_modal__zoyfa`,
	"container": `CardDeletionPopup_container__wep9E`,
	"heading": `CardDeletionPopup_heading__LBoq6`,
	"buttonsContainer": `CardDeletionPopup_buttonsContainer__b6jhi`
};
export default ___CSS_LOADER_EXPORT___;
