// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CardColumn-Calculator_container__R4Nlz {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  pointer-events: auto;
  padding-top: 15px;
  padding-bottom: 10px;
}
.CardColumn-Calculator_container__R4Nlz:not(:last-child) {
  margin-right: 7px;
}
.CardColumn-Calculator_heading__ofQfZ {
font-weight: 400;
  letter-spacing: 0em;
  text-align: center;

  margin-bottom: 4px;
  font-size: 13px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: center;
}
.CardColumn-Calculator_currentPillar__LUcAG {
  background-color: var(--mini-accent-color);
  position: relative;
}
`, "",{"version":3,"sources":["webpack://./src/Components/CardColumn/CardColumn-Calculator.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,oBAAoB;EACpB,iBAAiB;EACjB,oBAAoB;AACtB;AACA;EACE,iBAAiB;AACnB;AACA;AACA,gBAAgB;EACd,mBAAmB;EACnB,kBAAkB;;EAElB,kBAAkB;EAClB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,mBAAmB;EACnB,kBAAkB;AACpB;AACA;EACE,0CAA0C;EAC1C,kBAAkB;AACpB","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  pointer-events: auto;\n  padding-top: 15px;\n  padding-bottom: 10px;\n}\n.container:not(:last-child) {\n  margin-right: 7px;\n}\n.heading {\nfont-weight: 400;\n  letter-spacing: 0em;\n  text-align: center;\n\n  margin-bottom: 4px;\n  font-size: 13px;\n  font-weight: 400;\n  line-height: 15px;\n  letter-spacing: 0em;\n  text-align: center;\n}\n.currentPillar {\n  background-color: var(--mini-accent-color);\n  position: relative;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `CardColumn-Calculator_container__R4Nlz`,
	"heading": `CardColumn-Calculator_heading__ofQfZ`,
	"currentPillar": `CardColumn-Calculator_currentPillar__LUcAG`
};
export default ___CSS_LOADER_EXPORT___;
