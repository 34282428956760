// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NameHeading_container__hjubF {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  font-size: 16px;
  letter-spacing: 0.1 em;
  font-weight: 400;
  text-transform: uppercase;
}
.NameHeading_logo__sR7lf {
  margin-left: 10px;
  margin-right: 10px;
  max-width: 52px;
  max-height: 52px;
  background-color: white;
  overflow: hidden;
  border-width: -20px;
  border-radius: 50%;
  fill: var(--accent-color);
}
`, "",{"version":3,"sources":["webpack://./src/Components/NameHeading/NameHeading.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,6BAA6B;EAC7B,mBAAmB;EACnB,eAAe;EACf,sBAAsB;EACtB,gBAAgB;EAChB,yBAAyB;AAC3B;AACA;EACE,iBAAiB;EACjB,kBAAkB;EAClB,eAAe;EACf,gBAAgB;EAChB,uBAAuB;EACvB,gBAAgB;EAChB,mBAAmB;EACnB,kBAAkB;EAClB,yBAAyB;AAC3B","sourcesContent":[".container {\n  display: flex;\n  justify-content: space-evenly;\n  align-items: center;\n  font-size: 16px;\n  letter-spacing: 0.1 em;\n  font-weight: 400;\n  text-transform: uppercase;\n}\n.logo {\n  margin-left: 10px;\n  margin-right: 10px;\n  max-width: 52px;\n  max-height: 52px;\n  background-color: white;\n  overflow: hidden;\n  border-width: -20px;\n  border-radius: 50%;\n  fill: var(--accent-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `NameHeading_container__hjubF`,
	"logo": `NameHeading_logo__sR7lf`
};
export default ___CSS_LOADER_EXPORT___;
