// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CardInfo-CardGridItem_container__HhKZC {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: calc((100vw - 13px * 4 - 1px) / 2 - 13px);
  @media screen and (min-width: 400px) {
    width: calc((400px - 13px * 4 - 1px) / 2 - 13px);
  }
}
.CardInfo-CardGridItem_separator__0JpqI {
  display: none;
}
`, "",{"version":3,"sources":["webpack://./src/Components/CardInfo/CardInfo-CardGridItem.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,6BAA6B;EAC7B,mBAAmB;EACnB,gDAAgD;EAChD;IACE,gDAAgD;EAClD;AACF;AACA;EACE,aAAa;AACf","sourcesContent":[".container {\n  display: flex;\n  justify-content: space-evenly;\n  align-items: center;\n  width: calc((100vw - 13px * 4 - 1px) / 2 - 13px);\n  @media screen and (min-width: 400px) {\n    width: calc((400px - 13px * 4 - 1px) / 2 - 13px);\n  }\n}\n.separator {\n  display: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `CardInfo-CardGridItem_container__HhKZC`,
	"separator": `CardInfo-CardGridItem_separator__0JpqI`
};
export default ___CSS_LOADER_EXPORT___;
