// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.IconedCardInfoListItem-CardGridItem_container__Fcflb {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
}
.IconedCardInfoListItem-CardGridItem_icon__uqKX6 {
  width: 13px;
  height: 13px;
  fill: white;
  margin-right: 2px;
}
.IconedCardInfoListItem-CardGridItem_value__hBKPD {
  color: var(--main-text-color);
  line-height: 13px;
  font-size: 13px;
}
`, "",{"version":3,"sources":["webpack://./src/Components/IconedCardInfoListItem/IconedCardInfoListItem-CardGridItem.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,kBAAkB;AACpB;AACA;EACE,WAAW;EACX,YAAY;EACZ,WAAW;EACX,iBAAiB;AACnB;AACA;EACE,6BAA6B;EAC7B,iBAAiB;EACjB,eAAe;AACjB","sourcesContent":[".container {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  margin-bottom: 5px;\n}\n.icon {\n  width: 13px;\n  height: 13px;\n  fill: white;\n  margin-right: 2px;\n}\n.value {\n  color: var(--main-text-color);\n  line-height: 13px;\n  font-size: 13px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `IconedCardInfoListItem-CardGridItem_container__Fcflb`,
	"icon": `IconedCardInfoListItem-CardGridItem_icon__uqKX6`,
	"value": `IconedCardInfoListItem-CardGridItem_value__hBKPD`
};
export default ___CSS_LOADER_EXPORT___;
