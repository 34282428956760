// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MainPage_wrapper__6Tf2r {
  min-height: 100%;
  position: relative;
  overflow: hidden;
}
.MainPage_section__QhPKj {
  height: 100%;
  padding-top: 52px;
}
.MainPage_circleInfo__4d6zX {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 13px;
}
.MainPage_addIcon__54Fe\\+ {
  fill: var(--background-color);
  width: 40px;
  height: 40px;
  transform: translateY(-20px);
}
.MainPage_starIcon__CaJDU {
  fill: var(--background-color);
  width: 42px;
  height: 40px;
  transform: translateY(-20px);
}
.MainPage_animalLogoContainer__kCJ2W {
  border-top: 1px solid var(--background-color);
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 400px;
  padding-top: 16px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 16px;
}
.MainPage_modal__T-iqh {
  width: 100%;
  padding: 20px;
  & > *:not(:last-child) {
    margin-bottom: 28px;
  }
  background-color: var(--background-color);
}
`, "",{"version":3,"sources":["webpack://./src/Pages/MainPage/MainPage.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,kBAAkB;EAClB,gBAAgB;AAClB;AACA;EACE,YAAY;EACZ,iBAAiB;AACnB;AACA;EACE,aAAa;EACb,8BAA8B;EAC9B,qBAAqB;EACrB,aAAa;AACf;AACA;EACE,6BAA6B;EAC7B,WAAW;EACX,YAAY;EACZ,4BAA4B;AAC9B;AACA;EACE,6BAA6B;EAC7B,WAAW;EACX,YAAY;EACZ,4BAA4B;AAC9B;AACA;EACE,6CAA6C;EAC7C,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,gBAAgB;EAChB,iBAAiB;EACjB,kBAAkB;EAClB,mBAAmB;EACnB,oBAAoB;AACtB;AACA;EACE,WAAW;EACX,aAAa;EACb;IACE,mBAAmB;EACrB;EACA,yCAAyC;AAC3C","sourcesContent":[".wrapper {\n  min-height: 100%;\n  position: relative;\n  overflow: hidden;\n}\n.section {\n  height: 100%;\n  padding-top: 52px;\n}\n.circleInfo {\n  display: flex;\n  justify-content: space-between;\n  align-items: flex-end;\n  padding: 13px;\n}\n.addIcon {\n  fill: var(--background-color);\n  width: 40px;\n  height: 40px;\n  transform: translateY(-20px);\n}\n.starIcon {\n  fill: var(--background-color);\n  width: 42px;\n  height: 40px;\n  transform: translateY(-20px);\n}\n.animalLogoContainer {\n  border-top: 1px solid var(--background-color);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  max-width: 400px;\n  padding-top: 16px;\n  padding-left: 16px;\n  padding-right: 16px;\n  padding-bottom: 16px;\n}\n.modal {\n  width: 100%;\n  padding: 20px;\n  & > *:not(:last-child) {\n    margin-bottom: 28px;\n  }\n  background-color: var(--background-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `MainPage_wrapper__6Tf2r`,
	"section": `MainPage_section__QhPKj`,
	"circleInfo": `MainPage_circleInfo__4d6zX`,
	"addIcon": `MainPage_addIcon__54Fe+`,
	"starIcon": `MainPage_starIcon__CaJDU`,
	"animalLogoContainer": `MainPage_animalLogoContainer__kCJ2W`,
	"modal": `MainPage_modal__T-iqh`
};
export default ___CSS_LOADER_EXPORT___;
