// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Loading_showed__ryJHT,
.Loading_hidden__4WYo7 {
  background-color: var(--accent-color);
  height: 100vh;
  width: 100%;
  max-width: 400px;
  position: absolute;
  overflow: hidden;
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  z-index: 10000;
}
.Loading_showed__ryJHT {
  transition: none;
}
.Loading_hidden__4WYo7 {
  opacity: 0;
  pointer-events: none;
  transition: opacity 1000ms ease;
}
@keyframes Loading_rotate__Rs6se {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.Loading_icon__gtwJW {
  width: 70%;
  border-radius: 50%;
  border-width: 0px;
  animation: Loading_rotate__Rs6se 10s linear infinite;
  fill: var(--accent-color);
  background-color: white;
}
`, "",{"version":3,"sources":["webpack://./src/Components/Loading/Loading.module.css"],"names":[],"mappings":"AAAA;;EAEE,qCAAqC;EACrC,aAAa;EACb,WAAW;EACX,gBAAgB;EAChB,kBAAkB;EAClB,gBAAgB;EAChB,oBAAoB;EACpB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,MAAM;EACN,OAAO;EACP,cAAc;AAChB;AACA;EACE,gBAAgB;AAClB;AACA;EACE,UAAU;EACV,oBAAoB;EACpB,+BAA+B;AACjC;AACA;EACE;IACE,uBAAuB;EACzB;EACA;IACE,yBAAyB;EAC3B;AACF;AACA;EACE,UAAU;EACV,kBAAkB;EAClB,iBAAiB;EACjB,oDAAqC;EACrC,yBAAyB;EACzB,uBAAuB;AACzB","sourcesContent":[".showed,\n.hidden {\n  background-color: var(--accent-color);\n  height: 100vh;\n  width: 100%;\n  max-width: 400px;\n  position: absolute;\n  overflow: hidden;\n  pointer-events: none;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  top: 0;\n  left: 0;\n  z-index: 10000;\n}\n.showed {\n  transition: none;\n}\n.hidden {\n  opacity: 0;\n  pointer-events: none;\n  transition: opacity 1000ms ease;\n}\n@keyframes rotate {\n  from {\n    transform: rotate(0deg);\n  }\n  to {\n    transform: rotate(360deg);\n  }\n}\n.icon {\n  width: 70%;\n  border-radius: 50%;\n  border-width: 0px;\n  animation: rotate 10s linear infinite;\n  fill: var(--accent-color);\n  background-color: white;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"showed": `Loading_showed__ryJHT`,
	"hidden": `Loading_hidden__4WYo7`,
	"icon": `Loading_icon__gtwJW`,
	"rotate": `Loading_rotate__Rs6se`
};
export default ___CSS_LOADER_EXPORT___;
