// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FallingStarsField_container__ci2Am {
  height: 100%;
  aspect-ratio: 1 / 1;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr; /* Задает три колонки равной ширины */
  gap: 2px; /* Задает промежуток между элементами */
}
`, "",{"version":3,"sources":["webpack://./src/Components/FallingStarsField/FallingStarsField.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,mBAAmB;EACnB,aAAa;EACb,kCAAkC,EAAE,qCAAqC;EACzE,QAAQ,EAAE,uCAAuC;AACnD","sourcesContent":[".container {\n  height: 100%;\n  aspect-ratio: 1 / 1;\n  display: grid;\n  grid-template-columns: 1fr 1fr 1fr; /* Задает три колонки равной ширины */\n  gap: 2px; /* Задает промежуток между элементами */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `FallingStarsField_container__ci2Am`
};
export default ___CSS_LOADER_EXPORT___;
