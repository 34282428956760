// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Pic-Examples_image__inlWj {
  width: 100%;
  height: 100%;
}
.Pic-Examples_imageWrapper__\\+9Mwx {
  width: 30px;
  height: auto;
  margin-bottom: 4px;
  box-shadow: var(--bottom-shadow);
}
.Pic-Examples_whiteBorder__Gv8-U,
.Pic-Examples_grayBorder__0vaGc,
.Pic-Examples_blackBorder__yGBZv {
  border-width: 3px;
  border-radius: 5px;
  overflow: hidden;
  border-style: solid;
}
.Pic-Examples_whiteBorder__Gv8-U {
  border-color: white;
  background-color: white;
}
.Pic-Examples_grayBorder__0vaGc {
  border-color: gray;
  background-color: gray;
}
.Pic-Examples_blackBorder__yGBZv {
  width: 100%;
  height: 100%;
  border-color: black;
  background-color: black;
}
.Pic-Examples_name__SW7JX {
  display: none;
  font-family: Roboto;
  font-size: 13px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;

  margin-bottom: 4px;
}
`, "",{"version":3,"sources":["webpack://./src/Components/AnimalElementPic/Pic-Examples.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;AACd;AACA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,gCAAgC;AAClC;AACA;;;EAGE,iBAAiB;EACjB,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;AACrB;AACA;EACE,mBAAmB;EACnB,uBAAuB;AACzB;AACA;EACE,kBAAkB;EAClB,sBAAsB;AACxB;AACA;EACE,WAAW;EACX,YAAY;EACZ,mBAAmB;EACnB,uBAAuB;AACzB;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,mBAAmB;EACnB,gBAAgB;;EAEhB,kBAAkB;AACpB","sourcesContent":[".image {\n  width: 100%;\n  height: 100%;\n}\n.imageWrapper {\n  width: 30px;\n  height: auto;\n  margin-bottom: 4px;\n  box-shadow: var(--bottom-shadow);\n}\n.whiteBorder,\n.grayBorder,\n.blackBorder {\n  border-width: 3px;\n  border-radius: 5px;\n  overflow: hidden;\n  border-style: solid;\n}\n.whiteBorder {\n  border-color: white;\n  background-color: white;\n}\n.grayBorder {\n  border-color: gray;\n  background-color: gray;\n}\n.blackBorder {\n  width: 100%;\n  height: 100%;\n  border-color: black;\n  background-color: black;\n}\n.name {\n  display: none;\n  font-family: Roboto;\n  font-size: 13px;\n  font-weight: 400;\n  line-height: 15px;\n  letter-spacing: 0em;\n  text-align: left;\n\n  margin-bottom: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"image": `Pic-Examples_image__inlWj`,
	"imageWrapper": `Pic-Examples_imageWrapper__+9Mwx`,
	"whiteBorder": `Pic-Examples_whiteBorder__Gv8-U`,
	"grayBorder": `Pic-Examples_grayBorder__0vaGc`,
	"blackBorder": `Pic-Examples_blackBorder__yGBZv`,
	"name": `Pic-Examples_name__SW7JX`
};
export default ___CSS_LOADER_EXPORT___;
