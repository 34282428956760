// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ModalCardInfo_container__LTyeE {
  background-color: var(--background-color);
  padding-top: 23px;
  padding-bottom: 23px;
  padding-left: 19px;
  padding-right: 19px;
  position: relative;
}
.ModalCardInfo_editIcon__X1Sil {
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  width: 26px;
  height: 26px;
  fill: var(--accent-color);
  position: absolute;
  top: 23px;
  left: 25px;
}
.ModalCardInfo_listContainer__XHa5T {
}
.ModalCardInfo_list__1KiZQ {
  padding-left: 13px;
  padding-right: 13px;
  display: flex;
  overflow: scroll;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 14px;
}
.ModalCardInfo_collisionsList__ddCbz {
  margin-bottom: 19px;
}
.ModalCardInfo_editingButtonsContainer__0efLx {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
`, "",{"version":3,"sources":["webpack://./src/Components/ModalComponents/ModalCardInfo/ModalCardInfo.module.css"],"names":[],"mappings":"AAAA;EACE,yCAAyC;EACzC,iBAAiB;EACjB,oBAAoB;EACpB,kBAAkB;EAClB,mBAAmB;EACnB,kBAAkB;AACpB;AACA;EAEE,oDAAoD;EACpD,WAAW;EACX,YAAY;EACZ,yBAAyB;EACzB,kBAAkB;EAClB,SAAS;EACT,UAAU;AACZ;AACA;AACA;AACA;EACE,kBAAkB;EAClB,mBAAmB;EACnB,aAAa;EACb,gBAAgB;EAChB,uBAAuB;EACvB,8BAA8B;EAC9B,mBAAmB;AACrB;AACA;EACE,mBAAmB;AACrB;AACA;EACE,aAAa;EACb,6BAA6B;EAC7B,mBAAmB;AACrB","sourcesContent":[".container {\n  background-color: var(--background-color);\n  padding-top: 23px;\n  padding-bottom: 23px;\n  padding-left: 19px;\n  padding-right: 19px;\n  position: relative;\n}\n.editIcon {\n  -webkit-filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));\n  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));\n  width: 26px;\n  height: 26px;\n  fill: var(--accent-color);\n  position: absolute;\n  top: 23px;\n  left: 25px;\n}\n.listContainer {\n}\n.list {\n  padding-left: 13px;\n  padding-right: 13px;\n  display: flex;\n  overflow: scroll;\n  align-items: flex-start;\n  justify-content: space-between;\n  margin-bottom: 14px;\n}\n.collisionsList {\n  margin-bottom: 19px;\n}\n.editingButtonsContainer {\n  display: flex;\n  justify-content: space-around;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `ModalCardInfo_container__LTyeE`,
	"editIcon": `ModalCardInfo_editIcon__X1Sil`,
	"listContainer": `ModalCardInfo_listContainer__XHa5T`,
	"list": `ModalCardInfo_list__1KiZQ`,
	"collisionsList": `ModalCardInfo_collisionsList__ddCbz`,
	"editingButtonsContainer": `ModalCardInfo_editingButtonsContainer__0efLx`
};
export default ___CSS_LOADER_EXPORT___;
