// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AnimalLogo-HomePage_icon__tXWCT {
  width: 100%;
  max-width: 50%;
  height: 100%;
  max-height: calc(100vw / 100 * 40);
  fill: white;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  @media screen and (min-width: 400px) {
    max-height: calc(400px / 100 * 40);
  }
}
`, "",{"version":3,"sources":["webpack://./src/Components/AnimalLogo/AnimalLogo-HomePage.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,cAAc;EACd,YAAY;EACZ,kCAAkC;EAClC,WAAW;EAEX,oDAAoD;EACpD;IACE,kCAAkC;EACpC;AACF","sourcesContent":[".icon {\n  width: 100%;\n  max-width: 50%;\n  height: 100%;\n  max-height: calc(100vw / 100 * 40);\n  fill: white;\n  -webkit-filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));\n  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));\n  @media screen and (min-width: 400px) {\n    max-height: calc(400px / 100 * 40);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"icon": `AnimalLogo-HomePage_icon__tXWCT`
};
export default ___CSS_LOADER_EXPORT___;
