// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App_App__B2Ebb {
  text-align: center;
}
.App_wrapper__Mru9Z {
  max-width: 400px;
  margin-right: auto;
  margin-left: auto;
  min-height: 100dvh;
  position: relative;
}
.App_container__Rt8T2 {
  min-height: 100dvh;
  & > :nth-child(2) {
    min-height: calc(100dvh - 52px - 60px);
    position: relative;
  }
  position: relative;
}
.App_App-logo__yMrik {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App_App-logo__yMrik {
    animation: App_App-logo-spin__1up6W infinite 20s linear;
  }
}

.App_App-header__PyRwd {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App_App-link__XPeK4 {
  color: #61dafb;
}

@keyframes App_App-logo-spin__1up6W {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
`, "",{"version":3,"sources":["webpack://./src/App.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;AACA;EACE,gBAAgB;EAChB,kBAAkB;EAClB,iBAAiB;EACjB,kBAAkB;EAClB,kBAAkB;AACpB;AACA;EACE,kBAAkB;EAClB;IACE,sCAAsC;IACtC,kBAAkB;EACpB;EACA,kBAAkB;AACpB;AACA;EACE,cAAc;EACd,oBAAoB;AACtB;;AAEA;EACE;IACE,uDAA4C;EAC9C;AACF;;AAEA;EACE,yBAAyB;EACzB,iBAAiB;EACjB,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,6BAA6B;EAC7B,YAAY;AACd;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE;IACE,uBAAuB;EACzB;EACA;IACE,yBAAyB;EAC3B;AACF","sourcesContent":[".App {\n  text-align: center;\n}\n.wrapper {\n  max-width: 400px;\n  margin-right: auto;\n  margin-left: auto;\n  min-height: 100dvh;\n  position: relative;\n}\n.container {\n  min-height: 100dvh;\n  & > :nth-child(2) {\n    min-height: calc(100dvh - 52px - 60px);\n    position: relative;\n  }\n  position: relative;\n}\n.App-logo {\n  height: 40vmin;\n  pointer-events: none;\n}\n\n@media (prefers-reduced-motion: no-preference) {\n  .App-logo {\n    animation: App-logo-spin infinite 20s linear;\n  }\n}\n\n.App-header {\n  background-color: #282c34;\n  min-height: 100vh;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  font-size: calc(10px + 2vmin);\n  color: white;\n}\n\n.App-link {\n  color: #61dafb;\n}\n\n@keyframes App-logo-spin {\n  from {\n    transform: rotate(0deg);\n  }\n  to {\n    transform: rotate(360deg);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"App": `App_App__B2Ebb`,
	"wrapper": `App_wrapper__Mru9Z`,
	"container": `App_container__Rt8T2`,
	"App-logo": `App_App-logo__yMrik`,
	"App-logo-spin": `App_App-logo-spin__1up6W`,
	"App-header": `App_App-header__PyRwd`,
	"App-link": `App_App-link__XPeK4`
};
export default ___CSS_LOADER_EXPORT___;
