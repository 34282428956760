// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DaysLine_container__SOaqx {
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 11px;
  padding-right: 11px;

  background-color: var(--background-color);
  position: fixed;
  width: 100%;
  max-width: 400px;
  z-index: 100;

  box-shadow: var(--bottom-shadow);

  height: 52px;
  display: flex;
  align-items: flex-start;
}
.DaysLine_arrow__eZmSA {
  height: 1em;
  width: auto;
  margin-top: 4px;
  cursor: pointer;
  fill: var(--accent-color);
  &:first-child {
    margin-right: 3px;
  }
  &:last-child {
    margin-left: 3px;
  }
}
.DaysLine_daysContainer__JgyLz {
  display: flex;
  align-items: flex-start;
  width: 100%;
  justify-content: space-between;
}
.DaysLine_dayItem__P\\+QIw {
  color: var(--inactive-text-color);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  cursor: pointer;
}
.DaysLine_currentDayItem__OlayW {
  cursor: auto;
  color: var(--main-text-color);
}
.DaysLine_date__UoAA0 {
  font-size: 20px;
  line-height: normal;
}
.DaysLine_dayOfWeek__qYFDo {
  font-size: 13px;
  line-height: normal;
}
`, "",{"version":3,"sources":["webpack://./src/Components/DaysLine/DaysLine.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,mBAAmB;EACnB,kBAAkB;EAClB,mBAAmB;;EAEnB,yCAAyC;EACzC,eAAe;EACf,WAAW;EACX,gBAAgB;EAChB,YAAY;;EAEZ,gCAAgC;;EAEhC,YAAY;EACZ,aAAa;EACb,uBAAuB;AACzB;AACA;EACE,WAAW;EACX,WAAW;EACX,eAAe;EACf,eAAe;EACf,yBAAyB;EACzB;IACE,iBAAiB;EACnB;EACA;IACE,gBAAgB;EAClB;AACF;AACA;EACE,aAAa;EACb,uBAAuB;EACvB,WAAW;EACX,8BAA8B;AAChC;AACA;EACE,iCAAiC;EACjC,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;;EAEnB,eAAe;AACjB;AACA;EACE,YAAY;EACZ,6BAA6B;AAC/B;AACA;EACE,eAAe;EACf,mBAAmB;AACrB;AACA;EACE,eAAe;EACf,mBAAmB;AACrB","sourcesContent":[".container {\n  padding-top: 4px;\n  padding-bottom: 4px;\n  padding-left: 11px;\n  padding-right: 11px;\n\n  background-color: var(--background-color);\n  position: fixed;\n  width: 100%;\n  max-width: 400px;\n  z-index: 100;\n\n  box-shadow: var(--bottom-shadow);\n\n  height: 52px;\n  display: flex;\n  align-items: flex-start;\n}\n.arrow {\n  height: 1em;\n  width: auto;\n  margin-top: 4px;\n  cursor: pointer;\n  fill: var(--accent-color);\n  &:first-child {\n    margin-right: 3px;\n  }\n  &:last-child {\n    margin-left: 3px;\n  }\n}\n.daysContainer {\n  display: flex;\n  align-items: flex-start;\n  width: 100%;\n  justify-content: space-between;\n}\n.dayItem {\n  color: var(--inactive-text-color);\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n\n  cursor: pointer;\n}\n.currentDayItem {\n  cursor: auto;\n  color: var(--main-text-color);\n}\n.date {\n  font-size: 20px;\n  line-height: normal;\n}\n.dayOfWeek {\n  font-size: 13px;\n  line-height: normal;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `DaysLine_container__SOaqx`,
	"arrow": `DaysLine_arrow__eZmSA`,
	"daysContainer": `DaysLine_daysContainer__JgyLz`,
	"dayItem": `DaysLine_dayItem__P+QIw`,
	"currentDayItem": `DaysLine_currentDayItem__OlayW`,
	"date": `DaysLine_date__UoAA0`,
	"dayOfWeek": `DaysLine_dayOfWeek__qYFDo`
};
export default ___CSS_LOADER_EXPORT___;
