// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CardInfo-Calculator_container__ldJJy {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 26px;
  padding-right: 26px;

  @media screen and (max-width: 340px) {
    padding-left: 13px;
    padding-right: 13px;
  }

  position: relative;

  box-shadow: var(--top-shadow);

  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  background-color: var(--light-accent-color);
}
.CardInfo-Calculator_separator__2t7Cn {
  height: 101%;
  position: absolute;
  top: -0.1px;
  left: 0;
  transform: translateX(
    calc(26px + 52px * 4 + ((100vw - 26px * 2 - 52px * 5) / 4 * 3.5))
  );
  width: 2px;
  background-color: white;
}
`, "",{"version":3,"sources":["webpack://./src/Components/CardInfo/CardInfo-Calculator.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,oBAAoB;EACpB,kBAAkB;EAClB,mBAAmB;;EAEnB;IACE,kBAAkB;IAClB,mBAAmB;EACrB;;EAEA,kBAAkB;;EAElB,6BAA6B;;EAE7B,aAAa;EACb,8BAA8B;EAC9B,uBAAuB;;EAEvB,2CAA2C;AAC7C;AACA;EACE,YAAY;EACZ,kBAAkB;EAClB,WAAW;EACX,OAAO;EACP;;GAEC;EACD,UAAU;EACV,uBAAuB;AACzB","sourcesContent":[".container {\n  padding-top: 10px;\n  padding-bottom: 10px;\n  padding-left: 26px;\n  padding-right: 26px;\n\n  @media screen and (max-width: 340px) {\n    padding-left: 13px;\n    padding-right: 13px;\n  }\n\n  position: relative;\n\n  box-shadow: var(--top-shadow);\n\n  display: flex;\n  justify-content: space-between;\n  align-items: flex-start;\n\n  background-color: var(--light-accent-color);\n}\n.separator {\n  height: 101%;\n  position: absolute;\n  top: -0.1px;\n  left: 0;\n  transform: translateX(\n    calc(26px + 52px * 4 + ((100vw - 26px * 2 - 52px * 5) / 4 * 3.5))\n  );\n  width: 2px;\n  background-color: white;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `CardInfo-Calculator_container__ldJJy`,
	"separator": `CardInfo-Calculator_separator__2t7Cn`
};
export default ___CSS_LOADER_EXPORT___;
