// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ModalMainElementStar_section__7RLYb {
  background-color: var(--background-color);
}
.ModalMainElementStar_container__WnaKU {
  margin-right: auto;
  padding-top: 17px;
  margin-left: auto;
}
.ModalMainElementStar_imageContainer__vwcxZ {
  margin-top: 17px;
  width: 100%;
  background-color: var(--accent-color);
  padding: 29px;
}
.ModalMainElementStar_image__237o1 {
  margin-right: auto;
  margin-left: auto;
  width: 218px;
  height: 226px;
}
.ModalMainElementStar_exampleList__uZmXO {
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 30px;
  margin-left: auto;
  margin-right: auto;
}
.ModalMainElementStar_exampleItem__h\\+dKw {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  &:not(:last-child) {
    margin-bottom: 15px;
  }
}
.ModalMainElementStar_exampleIcon__Fywna {
  margin-right: 12px;
  width: 43px;
  height: 43px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}
.ModalMainElementStar_exampleTitle__G0Iph {
  font-size: 15px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
}
`, "",{"version":3,"sources":["webpack://./src/Components/ModalComponents/ModalMainElementStar/ModalMainElementStar.module.css"],"names":[],"mappings":"AAAA;EACE,yCAAyC;AAC3C;AACA;EACE,kBAAkB;EAClB,iBAAiB;EACjB,iBAAiB;AACnB;AACA;EACE,gBAAgB;EAChB,WAAW;EACX,qCAAqC;EACrC,aAAa;AACf;AACA;EACE,kBAAkB;EAClB,iBAAiB;EACjB,YAAY;EACZ,aAAa;AACf;AACA;EACE,iBAAiB;EACjB,oBAAoB;EACpB,kBAAkB;EAClB,iBAAiB;EACjB,kBAAkB;AACpB;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,2BAA2B;EAC3B;IACE,mBAAmB;EACrB;AACF;AACA;EACE,kBAAkB;EAClB,WAAW;EACX,YAAY;EAEZ,oDAAoD;AACtD;AACA;EACE,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,mBAAmB;EACnB,gBAAgB;AAClB","sourcesContent":[".section {\n  background-color: var(--background-color);\n}\n.container {\n  margin-right: auto;\n  padding-top: 17px;\n  margin-left: auto;\n}\n.imageContainer {\n  margin-top: 17px;\n  width: 100%;\n  background-color: var(--accent-color);\n  padding: 29px;\n}\n.image {\n  margin-right: auto;\n  margin-left: auto;\n  width: 218px;\n  height: 226px;\n}\n.exampleList {\n  padding-top: 30px;\n  padding-bottom: 30px;\n  padding-left: 30px;\n  margin-left: auto;\n  margin-right: auto;\n}\n.exampleItem {\n  display: flex;\n  align-items: center;\n  justify-content: flex-start;\n  &:not(:last-child) {\n    margin-bottom: 15px;\n  }\n}\n.exampleIcon {\n  margin-right: 12px;\n  width: 43px;\n  height: 43px;\n  -webkit-filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));\n  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));\n}\n.exampleTitle {\n  font-size: 15px;\n  font-weight: 400;\n  line-height: 15px;\n  letter-spacing: 0em;\n  text-align: left;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"section": `ModalMainElementStar_section__7RLYb`,
	"container": `ModalMainElementStar_container__WnaKU`,
	"imageContainer": `ModalMainElementStar_imageContainer__vwcxZ`,
	"image": `ModalMainElementStar_image__237o1`,
	"exampleList": `ModalMainElementStar_exampleList__uZmXO`,
	"exampleItem": `ModalMainElementStar_exampleItem__h+dKw`,
	"exampleIcon": `ModalMainElementStar_exampleIcon__Fywna`,
	"exampleTitle": `ModalMainElementStar_exampleTitle__G0Iph`
};
export default ___CSS_LOADER_EXPORT___;
