// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ElementsExamples_container__2VX0y {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 15px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  &:not(:last-child) {
    margin-bottom: 10px;
  }
  & > *:nth-child(1) {
    /* transform: scale(0.7) translateX(-30%); */
  }
}
.ElementsExamples_blackContainer__8dYG8 {
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > .ElementsExamples_image__2qlGk {
    width: 30px;
  }
  & > *:nth-child(1) {
    margin-right: 10px;
  }
}
.ElementsExamples_image__2qlGk {
  width: 36px;
  border-radius: 5px;
}
.ElementsExamples_text__F39HC {
  margin-left: 10px;
  text-align: left;
}
`, "",{"version":3,"sources":["webpack://./src/Components/ElementsExamples/ElementsExamples.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,2BAA2B;EAC3B,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,mBAAmB;EACnB,gBAAgB;EAChB;IACE,mBAAmB;EACrB;EACA;IACE,4CAA4C;EAC9C;AACF;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;;EAE9B;IACE,WAAW;EACb;EACA;IACE,kBAAkB;EACpB;AACF;AACA;EACE,WAAW;EACX,kBAAkB;AACpB;AACA;EACE,iBAAiB;EACjB,gBAAgB;AAClB","sourcesContent":[".container {\n  display: flex;\n  align-items: center;\n  justify-content: flex-start;\n  font-size: 15px;\n  font-weight: 400;\n  line-height: 15px;\n  letter-spacing: 0em;\n  text-align: left;\n  &:not(:last-child) {\n    margin-bottom: 10px;\n  }\n  & > *:nth-child(1) {\n    /* transform: scale(0.7) translateX(-30%); */\n  }\n}\n.blackContainer {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n\n  & > .image {\n    width: 30px;\n  }\n  & > *:nth-child(1) {\n    margin-right: 10px;\n  }\n}\n.image {\n  width: 36px;\n  border-radius: 5px;\n}\n.text {\n  margin-left: 10px;\n  text-align: left;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `ElementsExamples_container__2VX0y`,
	"blackContainer": `ElementsExamples_blackContainer__8dYG8`,
	"image": `ElementsExamples_image__2qlGk`,
	"text": `ElementsExamples_text__F39HC`
};
export default ___CSS_LOADER_EXPORT___;
