// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.IconedCardInfoListItem-Calculator_container__pd0CA {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.IconedCardInfoListItem-Calculator_icon__rIkJY {
  width: 26px;
  height: 26px;
  fill: white;
  /* margin-right: 2px; */
  border-radius: 50%;
  box-shadow: var(--bottom-shadow);
}
.IconedCardInfoListItem-Calculator_value__GOehQ {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 28px;
}
`, "",{"version":3,"sources":["webpack://./src/Components/IconedCardInfoListItem/IconedCardInfoListItem-Calculator.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,kBAAkB;AACpB;AACA;EACE,WAAW;EACX,YAAY;EACZ,WAAW;EACX,uBAAuB;EACvB,kBAAkB;EAClB,gCAAgC;AAClC;AACA;EACE,kBAAkB;EAClB,QAAQ;EACR,2BAA2B;EAC3B,UAAU;AACZ","sourcesContent":[".container {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  position: relative;\n}\n.icon {\n  width: 26px;\n  height: 26px;\n  fill: white;\n  /* margin-right: 2px; */\n  border-radius: 50%;\n  box-shadow: var(--bottom-shadow);\n}\n.value {\n  position: absolute;\n  top: 50%;\n  transform: translateY(-50%);\n  left: 28px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `IconedCardInfoListItem-Calculator_container__pd0CA`,
	"icon": `IconedCardInfoListItem-Calculator_icon__rIkJY`,
	"value": `IconedCardInfoListItem-Calculator_value__GOehQ`
};
export default ___CSS_LOADER_EXPORT___;
