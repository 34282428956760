// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CardInfo-HomePage_container__Cba8B {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: calc((100% - 52px * 4) / 7);
  padding-right: calc((100% - 52px * 4) / 7);

  position: relative;

  border-top: 1px solid var(--background-color);

  max-width: 100vw;

  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
}
.CardInfo-HomePage_separator__s6UPr {
  display: none;
}
`, "",{"version":3,"sources":["webpack://./src/Components/CardInfo/CardInfo-HomePage.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,oBAAoB;EACpB,yCAAyC;EACzC,0CAA0C;;EAE1C,kBAAkB;;EAElB,6CAA6C;;EAE7C,gBAAgB;;EAEhB,aAAa;EACb,6BAA6B;EAC7B,uBAAuB;AACzB;AACA;EACE,aAAa;AACf","sourcesContent":[".container {\n  padding-top: 10px;\n  padding-bottom: 10px;\n  padding-left: calc((100% - 52px * 4) / 7);\n  padding-right: calc((100% - 52px * 4) / 7);\n\n  position: relative;\n\n  border-top: 1px solid var(--background-color);\n\n  max-width: 100vw;\n\n  display: flex;\n  justify-content: space-evenly;\n  align-items: flex-start;\n}\n.separator {\n  display: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `CardInfo-HomePage_container__Cba8B`,
	"separator": `CardInfo-HomePage_separator__s6UPr`
};
export default ___CSS_LOADER_EXPORT___;
