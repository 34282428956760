// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ModalIconedInfo_container__\\+ln9X {
  background-color: var(--background-color);
  padding-top: 26px;
  padding-bottom: 26px;
  padding-left: 45px;
  padding-right: 45px;
  width: 100%;
}
.ModalIconedInfo_list__Zrvtl {
  display: flex;
  flex-direction: column;
  align-items: space-between;
  justify-content: center;
  list-style: none;
  width: 100%;
}
.ModalIconedInfo_listItem__C3v4d {
  min-height: 34.5px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  &:not(:last-child) {
    margin-bottom: 28px;
  }
}
.ModalIconedInfo_icon__v7UD9 {
  min-width: 26px;
  min-height: 26px;
  max-width: 26px;
  max-height: 26px;
  margin-right: 3px;
  fill: var(--accent-color);
}
.ModalIconedInfo_value__RIIif {
  font-size: 20px;
  font-weight: 400;
  min-width: 47px;
  text-align: left;
  margin-right: 3px;
}
.ModalIconedInfo_description__Jgm\\+b {
  font-size: 15px;
  font-weight: 400;
  text-align: left;
}
`, "",{"version":3,"sources":["webpack://./src/Components/ModalComponents/ModalIconedInfo/ModalIconedInfo.module.css"],"names":[],"mappings":"AAAA;EACE,yCAAyC;EACzC,iBAAiB;EACjB,oBAAoB;EACpB,kBAAkB;EAClB,mBAAmB;EACnB,WAAW;AACb;AACA;EACE,aAAa;EACb,sBAAsB;EACtB,0BAA0B;EAC1B,uBAAuB;EACvB,gBAAgB;EAChB,WAAW;AACb;AACA;EACE,kBAAkB;EAClB,aAAa;EACb,mBAAmB;EACnB,2BAA2B;EAC3B;IACE,mBAAmB;EACrB;AACF;AACA;EACE,eAAe;EACf,gBAAgB;EAChB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,yBAAyB;AAC3B;AACA;EACE,eAAe;EACf,gBAAgB;EAChB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;AACnB;AACA;EACE,eAAe;EACf,gBAAgB;EAChB,gBAAgB;AAClB","sourcesContent":[".container {\n  background-color: var(--background-color);\n  padding-top: 26px;\n  padding-bottom: 26px;\n  padding-left: 45px;\n  padding-right: 45px;\n  width: 100%;\n}\n.list {\n  display: flex;\n  flex-direction: column;\n  align-items: space-between;\n  justify-content: center;\n  list-style: none;\n  width: 100%;\n}\n.listItem {\n  min-height: 34.5px;\n  display: flex;\n  align-items: center;\n  justify-content: flex-start;\n  &:not(:last-child) {\n    margin-bottom: 28px;\n  }\n}\n.icon {\n  min-width: 26px;\n  min-height: 26px;\n  max-width: 26px;\n  max-height: 26px;\n  margin-right: 3px;\n  fill: var(--accent-color);\n}\n.value {\n  font-size: 20px;\n  font-weight: 400;\n  min-width: 47px;\n  text-align: left;\n  margin-right: 3px;\n}\n.description {\n  font-size: 15px;\n  font-weight: 400;\n  text-align: left;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `ModalIconedInfo_container__+ln9X`,
	"list": `ModalIconedInfo_list__Zrvtl`,
	"listItem": `ModalIconedInfo_listItem__C3v4d`,
	"icon": `ModalIconedInfo_icon__v7UD9`,
	"value": `ModalIconedInfo_value__RIIif`,
	"description": `ModalIconedInfo_description__Jgm+b`
};
export default ___CSS_LOADER_EXPORT___;
