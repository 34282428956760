// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.IconedCardInfoList-CardGridItem_container__6AExP {
  margin-left: 10px;
  height: 85px;
}
.IconedCardInfoList-CardGridItem_list__xyTtM {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
`, "",{"version":3,"sources":["webpack://./src/Components/IconedCardInfoList/IconedCardInfoList-CardGridItem.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,YAAY;AACd;AACA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,uBAAuB;AACzB","sourcesContent":[".container {\n  margin-left: 10px;\n  height: 85px;\n}\n.list {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: flex-start;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `IconedCardInfoList-CardGridItem_container__6AExP`,
	"list": `IconedCardInfoList-CardGridItem_list__xyTtM`
};
export default ___CSS_LOADER_EXPORT___;
