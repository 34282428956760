// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PillarsInfo_container__6Fabx {
  background-color: var(--light-accent-color);
  box-shadow: var(--top-shadow);
}
.PillarsInfo_innerContainer__CE3EA {
  display: flex;
  overflow: scroll;
  padding-left: 23px;
  padding-right: 23px;
}
`, "",{"version":3,"sources":["webpack://./src/Components/PillarsInfo/PillarsInfo.module.css"],"names":[],"mappings":"AAAA;EACE,2CAA2C;EAC3C,6BAA6B;AAC/B;AACA;EACE,aAAa;EACb,gBAAgB;EAChB,kBAAkB;EAClB,mBAAmB;AACrB","sourcesContent":[".container {\n  background-color: var(--light-accent-color);\n  box-shadow: var(--top-shadow);\n}\n.innerContainer {\n  display: flex;\n  overflow: scroll;\n  padding-left: 23px;\n  padding-right: 23px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `PillarsInfo_container__6Fabx`,
	"innerContainer": `PillarsInfo_innerContainer__CE3EA`
};
export default ___CSS_LOADER_EXPORT___;
