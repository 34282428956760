// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.IconedCardInfoList-Calculator_container__dwoKW {
  padding-top: 19px;
  padding-bottom: 19px;
  padding-left: 13px;
  padding-right: calc(13px + 2rem);
  background-color: var(--light-accent-color);
}
.IconedCardInfoList-Calculator_list__1pjAS {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
`, "",{"version":3,"sources":["webpack://./src/Components/IconedCardInfoList/IconedCardInfoList-Calculator.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,oBAAoB;EACpB,kBAAkB;EAClB,gCAAgC;EAChC,2CAA2C;AAC7C;AACA;EACE,aAAa;EACb,6BAA6B;EAC7B,mBAAmB;AACrB","sourcesContent":[".container {\n  padding-top: 19px;\n  padding-bottom: 19px;\n  padding-left: 13px;\n  padding-right: calc(13px + 2rem);\n  background-color: var(--light-accent-color);\n}\n.list {\n  display: flex;\n  justify-content: space-around;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `IconedCardInfoList-Calculator_container__dwoKW`,
	"list": `IconedCardInfoList-Calculator_list__1pjAS`
};
export default ___CSS_LOADER_EXPORT___;
