// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CardOptionsItem_container__irwtq {
  border: 1px solid var(--border-color);
  border-radius: 5px;

  width: 86%;
  height: 14%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: backgroundColor 250ms linear, color 250ms linear;
}
.CardOptionsItem_notOpened__AXAib {
  background-color: white;
  color: var(--accent-color);
}
.CardOptionsItem_opened__kyxQt {
  background-color: var(--aoccent-clor);
  color: white;
}
`, "",{"version":3,"sources":["webpack://./src/Components/CardOptionsItem/CardOptionsItem.module.css"],"names":[],"mappings":"AAAA;EACE,qCAAqC;EACrC,kBAAkB;;EAElB,UAAU;EACV,WAAW;EACX,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,4DAA4D;AAC9D;AACA;EACE,uBAAuB;EACvB,0BAA0B;AAC5B;AACA;EACE,qCAAqC;EACrC,YAAY;AACd","sourcesContent":[".container {\n  border: 1px solid var(--border-color);\n  border-radius: 5px;\n\n  width: 86%;\n  height: 14%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  transition: backgroundColor 250ms linear, color 250ms linear;\n}\n.notOpened {\n  background-color: white;\n  color: var(--accent-color);\n}\n.opened {\n  background-color: var(--aoccent-clor);\n  color: white;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `CardOptionsItem_container__irwtq`,
	"notOpened": `CardOptionsItem_notOpened__AXAib`,
	"opened": `CardOptionsItem_opened__kyxQt`
};
export default ___CSS_LOADER_EXPORT___;
