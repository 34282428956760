// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CityInput_formFieldContainer__X4f4W {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-bottom: 35px;
}
.CityInput_label__Cn6cw {
  margin-right: 9px;
  text-transform: uppercase;
  font-family: Roboto Slab;
  font-size: 13px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  min-width: 104px;
  max-width: 104px;
  @media screen and (max-width: 360px) {
    min-width: 90px;
    max-width: 90px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/Components/CityInput/CityInput.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,6BAA6B;EAC7B,mBAAmB;AACrB;AACA;EACE,iBAAiB;EACjB,yBAAyB;EACzB,wBAAwB;EACxB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,mBAAmB;EACnB,gBAAgB;EAChB,gBAAgB;EAChB,gBAAgB;EAChB;IACE,eAAe;IACf,eAAe;EACjB;AACF","sourcesContent":[".formFieldContainer {\n  display: flex;\n  align-items: center;\n  justify-content: space-evenly;\n  margin-bottom: 35px;\n}\n.label {\n  margin-right: 9px;\n  text-transform: uppercase;\n  font-family: Roboto Slab;\n  font-size: 13px;\n  font-weight: 400;\n  line-height: 15px;\n  letter-spacing: 0em;\n  text-align: left;\n  min-width: 104px;\n  max-width: 104px;\n  @media screen and (max-width: 360px) {\n    min-width: 90px;\n    max-width: 90px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formFieldContainer": `CityInput_formFieldContainer__X4f4W`,
	"label": `CityInput_label__Cn6cw`
};
export default ___CSS_LOADER_EXPORT___;
