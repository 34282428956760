// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AnimalChart_container__nAHOn {
  height: 100%;
  position: relative;
  aspect-ratio: 1 / 1;
}
.AnimalChart_chart__iaXdR {
  margin-right: auto;
  margin-left: auto;
}
.AnimalChart_chartBack__qiE6b {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 10;
  stroke: white !important;
  & > * {
    stroke: white !important;
    stroke-width: 1.5px;
  }
  transform: translate(-50%, -50%) rotate(-0.5deg);
}
.AnimalChart_chartAnimalNames__cP6b0 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  rotate: 15deg;
  scale: -70%;
  z-index: 100;
}
`, "",{"version":3,"sources":["webpack://./src/Components/AnimalChart/AnimalChart.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,kBAAkB;EAClB,mBAAmB;AACrB;AACA;EACE,kBAAkB;EAClB,iBAAiB;AACnB;AACA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,WAAW;EACX,wBAAwB;EACxB;IACE,wBAAwB;IACxB,mBAAmB;EACrB;EACA,gDAAgD;AAClD;AACA;EACE,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;EACZ,aAAa;EACb,WAAW;EACX,YAAY;AACd","sourcesContent":[".container {\n  height: 100%;\n  position: relative;\n  aspect-ratio: 1 / 1;\n}\n.chart {\n  margin-right: auto;\n  margin-left: auto;\n}\n.chartBack {\n  width: 100%;\n  height: 100%;\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  z-index: 10;\n  stroke: white !important;\n  & > * {\n    stroke: white !important;\n    stroke-width: 1.5px;\n  }\n  transform: translate(-50%, -50%) rotate(-0.5deg);\n}\n.chartAnimalNames {\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  rotate: 15deg;\n  scale: -70%;\n  z-index: 100;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `AnimalChart_container__nAHOn`,
	"chart": `AnimalChart_chart__iaXdR`,
	"chartBack": `AnimalChart_chartBack__qiE6b`,
	"chartAnimalNames": `AnimalChart_chartAnimalNames__cP6b0`
};
export default ___CSS_LOADER_EXPORT___;
